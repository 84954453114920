<template>
  <div>
    <div class="head-section formation-img center">
      <div class="container">
        <p class="text-white">
          <span>{{ $t('Home') }} </span>
          &nbsp;
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.85547 10.3641L3.44663 6L7.85547 1.63594L6.20275 -7.22428e-08L0.141183 6L6.20275 12L7.85547 10.3641Z"
              fill="#AFAFAF" />
          </svg>
          &nbsp;
          {{ $t('BusinessSetup') }}
        </p>
       <div class="text-center mt-5 mb-3">
        <img src="../../../../assets/img/services-page/Company-formation-svg.png" alt="">
       </div>
        <h1 class="text-white mb-3" style="font-size: 24px;">{{ $t('BusinessSetup') }}</h1>
        <h2 class="text-white w8" style="font-size: 14px;">{{ $t('BusinessSetupDescription') }}</h2>
      </div>
    </div>
      <CompanyFormation />
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'Formation Page',
  components: {
    CompanyFormation: defineAsyncComponent(() => import('@/components/Services/StaticPages/CompanyFormation.vue')),
  },
}
</script>